import request from '@/utils/request'


// 查询收入流水业务列表
export function listIncomeBill(query) {
  return request({
    url: '/platform/income-bill/list',
    method: 'get',
    params: query
  })
}

// 查询收入流水业务分页
export function pageIncomeBill(query) {
  return request({
    url: '/platform/income-bill/page',
    method: 'get',
    params: query
  })
}

// 查询收入流水业务详细
export function getIncomeBill(data) {
  return request({
    url: '/platform/income-bill/detail',
    method: 'get',
    params: data
  })
}

// 新增收入流水业务
export function addIncomeBill(data) {
  return request({
    url: '/platform/income-bill/add',
    method: 'post',
    data: data
  })
}

// 修改收入流水业务
export function updateIncomeBill(data) {
  return request({
    url: '/platform/income-bill/edit',
    method: 'post',
    data: data
  })
}

// 删除收入流水业务
export function delIncomeBill(data) {
  return request({
    url: '/platform/income-bill/delete',
    method: 'post',
    data: data
  })
}
